/* Overall Container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('https://source.unsplash.com/1600x900/?abstract');
    background-size: cover;
    background-position: center;
  }
  
  /* Form Container */
  .login-form {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  /* Image */
  .login-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  /* Title */
  .login-title {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
  }
  
  /* Subtitle */
  .login-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Input Fields */
  .login-input {
    width: 100%;
    padding: 14px;
    margin: 10px 0;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  
  .login-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Button */
  .login-button {
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    background-color: #6200EE;
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  /* Spinner */
  .spinner {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  