@keyframes slideIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes buttonPop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animate-slide-in {
    animation: slideIn 0.6s ease-out forwards;
  }
  
  .animate-fade-in {
    animation: fadeIn 1s ease-in forwards;
  }
  
  .animate-button-pop {
    animation: buttonPop 0.3s ease-out forwards;
  }
  /* Override the default react-slick styles */
.slick-slider .slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
